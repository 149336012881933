import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
export var baseColors = {
    failure: "#ff0000",
    primary: "#FFC000",
    primaryBright: "#53DEE9",
    primaryDark: "#0098A1",
    secondary: "#FFC000",
    success: "#42cc02",
    warning: "#FFB237"
};
export var additionalColors = {
    binance: "#F0B90B",
    overlay: "#452a7a",
    gold: "#FFC700",
    silver: "#B2B2B2",
    bronze: "#E7974D"
};
export var lightColors = _object_spread_props(_object_spread({}, baseColors, additionalColors), {
    background: "#FAF9FA",
    backgroundDisabled: "#E9EAEB",
    backgroundAlt: "#FFFFFF",
    backgroundAlt2: "rgba(255, 255, 255, 0.7)",
    cardBorder: "#E7E3EB",
    contrast: "#191326",
    dropdown: "#F6F6F6",
    dropdownDeep: "#EEEEEE",
    invertedContrast: "#FFFFFF",
    input: "#eeeaf4",
    inputSecondary: "#d7caec",
    tertiary: "#EFF4F5",
    text: "#280D5F",
    textDisabled: "#BDC2C4",
    textSubtle: "#7A6EAA",
    disabled: "#E9EAEB",
    gradients: {
        bubblegum: "linear-gradient(139.73deg, #E5FDFF 0%, #F3EFFF 100%)",
        inverseBubblegum: "linear-gradient(139.73deg, #F3EFFF 0%, #E5FDFF 100%)",
        cardHeader: "linear-gradient(111.68deg, #F2ECF2 0%, #E8F2F6 100%)",
        blue: "linear-gradient(180deg, #A7E8F1 0%, #94E1F2 100%)",
        violet: "linear-gradient(180deg, #000000 0%, #000000 100%)",
        violetAlt: "linear-gradient(180deg, #000000 0%, #000000 100%)",
        gold: "linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)"
    }
});
export var darkColors = _object_spread_props(_object_spread({}, baseColors, additionalColors), {
    secondary: "#FFF",
    background: "#000000",
    backgroundDisabled: "#FFC000",
    backgroundAlt: "#000000",
    backgroundAlt2: "rgba(39, 38, 44, 0.7)",
    cardBorder: "#000002",
    contrast: "#FFFFFF",
    dropdown: "#000000",
    dropdownDeep: "#000000",
    invertedContrast: "#191326",
    input: "#FFF",
    inputSecondary: "#FFF",
    primaryDark: "#000000",
    tertiary: "#353547",
    text: "#FFF",
    textDisabled: "#666171",
    textSubtle: "#FFF",
    disabled: "#524B63",
    gradients: {
        bubblegum: "linear-gradient(139.73deg, #000000 0%, #00000 100%)",
        inverseBubblegum: "linear-gradient(139.73deg, #000000 0%, #000000 100%)",
        cardHeader: "linear-gradient(166.77deg, #000000 0%, #000000 100%)",
        blue: "linear-gradient(180deg, #000000 0%, #000000 100%)",
        violet: "linear-gradient(180deg, #000000 0%, #000000 100%)",
        violetAlt: "linear-gradient(180deg, #000000 0%, #000000 100%)",
        gold: "linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)"
    }
});
