import Metamask from "../../components/Svg/Icons/Metamask";
import Kaikas from "../../components/Svg/Icons/Kaikas";
import { ConnectorNames } from "./types";
var connectors = [
    {
        title: "Metamask",
        icon: Metamask,
        connectorId: ConnectorNames.MetaMask,
        priority: 2,
        href: "https://metamask.app.link/dapp/odiswap.netlify.app/"
    },
    // {
    //   title: "Binance Wallet",
    //   icon: BinanceChain,
    //   connectorId: ConnectorNames.BSC,
    //   priority: 2,
    // },
    // {
    //   title: "Coinbase Wallet",
    //   icon: CoinbaseWallet,
    //   connectorId: ConnectorNames.WalletLink,
    //   priority: 3,
    // },
    {
        title: "Kaikas",
        icon: Kaikas,
        connectorId: ConnectorNames.Kaikas,
        priority: 1,
        href: "https://odiswap.netlify.app/"
    }, 
];
export default connectors;
export var connectorLocalStorageKey = "connectorIdv2";
export var walletLocalStorageKey = "wallet";
export var walletConnectConfig = connectors.find(function(c) {
    return c.title === "WalletConnect";
});
